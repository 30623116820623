import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Box, Typography, ImageList, ImageListItem, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

const Container = styled(Box)({
    padding: '15px',
    border: '2px dashed rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    cursor: 'pointer'
});

const DeleteButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0
}));

const Dropzone = ({ label, files, setFiles, ...props }) => {
    const { t } = useTranslation();
    label = label || t('Ajouter des photos ou vidéos');

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (newFiles) => setFiles(state => ([ ...state, ...newFiles ])),
        ...props
    });

    const handleDelete = (index) => {
        const newFiles = [...files];  
        newFiles.splice(index, 1); 
        setFiles(newFiles);
    };

    const renderFiles = files.map((file, i) => (        
        <ImageListItem key={file.name}>
            {file.type.startsWith('video/') ? (
                <video width="100%"  controls controlsList="nodownload noplaybackrate">
                    <source src={URL.createObjectURL(file)}/>
                </video>
            ) : (
                <img src={URL.createObjectURL(file)} alt={file.name} />
            )}
            <DeleteButton onClick={() => handleDelete(i)} size="small">
                <DeleteIcon color="error" />
            </DeleteButton>
        </ImageListItem>
    ));

    return (
        <Box>
            <Container {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    <AddIcon /> {label}
                </Typography>
            </Container>
            {files.length > 0 && (
                <ImageList cols={2} rowHeight={200} variant="quilted" >
                    {renderFiles}
                </ImageList>
            )}
        </Box>
    );
};

Dropzone.defaultProps = {
    accept: 'image/*, video/*'
};

export default Dropzone;