import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import TicketMaterialList from './TicketMaterialList';
import AddMaterialButton from './AddMaterialButton';

const TicketMaterials = ({ record }) => {
	const [ticket, setTicket] = useState({});
	const { t } = useTranslation();

	useEffect(() => {
		setTicket(record);
	}, [record]);

	const updateTicket = (updatedTicket) => {
		setTicket(updatedTicket);
	};

	if (!record || !ticket) return null;

	return (
		<>
            <Typography variant="h5" gutterBottom>{t('Matériel utilisé')}</Typography>
			<TicketMaterialList ticket={ticket} updateTicket={updateTicket} />
			<AddMaterialButton ticket={ticket} updateTicket={updateTicket} />
		</>
	);
};

export default TicketMaterials;
