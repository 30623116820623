import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Stack, Box, Button, MenuItem } from '@mui/material';

const LANGS = [
    {
      label: 'FR',
      value: 'fr',
    },
    {
        label: 'EN',
        value: 'en',
    },
];

const LanguageSwitcher = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const langStorage = localStorage.getItem('i18nextLng');
    const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];
    const { i18n } = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button variant="outlined" onClick={handleClick}>
                {currentLang.label}
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Stack spacing={0.5}>
                    {LANGS.map((lang) => (
                        <MenuItem
                            key={lang.value}
                            selected={lang.value === currentLang.value}
                            onClick={() => {
                                i18n.changeLanguage(lang.value);
                                handleClose();
                            }}
                        >
                            {lang.label}
                        </MenuItem>
                    ))}
                </Stack>
            </Popover>
        </Box>
    );
};

export default LanguageSwitcher;
