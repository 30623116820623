import { UPDATE_FILTERS, RESET_FILTERS } from 'actions/filters';

const initialState = {
    q: '',
    asset: null,
	zipcode: null,
    priority: '',
    category: '',
	see_all: false,
	for_today: false
};

const filters = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_FILTERS:
			return { ...state, ...action.payload };
		case RESET_FILTERS:
			return initialState;
		default:
			return state;
	}
};

export default filters;