import React from 'react';
import ImageGallery from 'react-image-gallery';

import './Gallery.css';

const Gallery = ({ pictures, videos, ...props }) => {
    let items = pictures?.map(picture => ({ original: picture.url }));
    items = [...items, ...videos?.map(video => ({ renderItem: () => (
        <video width="100%" controls controlsList="nodownload noplaybackrate">
            <source src={video.url} />
        </video>
    ) }))];

    return (
        <ImageGallery items={items} {...props} showIndex />
    );
};

Gallery.defaultProps = {
    showPlayButton: false,
    useBrowserFullscreen: false
};

export default Gallery;