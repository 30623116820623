import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Box, Card, CardContent, Typography, TextField, Link, Button, FormControlLabel, Switch } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DateTime } from 'luxon';

import AssetAutocomplete from 'components/assets/AssetAutocomplete';
import Timer from 'components/common/Timer';
import TicketCard from './TicketCard';
import PriorityInput from './inputs/PriorityInput';
import CategoryInput from './inputs/CategoryInput';
import ZipcodeAutocomplete from './inputs/ZipcodeAutocomplete';
import LanguageSwitcher from 'components/common/LanguageSwitcher.js';

import { updateFilters } from 'actions/filters';

import { fetchTickets, fetchTicket, checkIn, checkOut, pauseTechnicianVisit, fetchLastTechnicianVisit } from 'services/api';
import { authLogout } from "../../actions/auth";

const TicketList = () => {
    const filters = useSelector(state => state.filters);
    const [searchTerm, setSearchTerm] = useState(filters.q);
    const [asset, setAsset] = useState(filters.asset);
    const [currentTicket, setCurrentTicket] = useState();
    const [zipcode, setZipcode] = useState(filters.zipcode);
    const [priority, setPriority] = useState(filters.priority);
    const [category, setCategory] = useState(filters.category);
    const [seeAll, setSeeAll] = useState(filters.see_all);
    const [forToday, setForToday] = useState(filters.for_today);
    const [tickets, setTickets] = useState([]);
    const [timerReady, setTimerReady] = useState(false);
    const [offsetTimer, setOffsetTimer] = useState();
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const fetchCurrentTicket = async () => {
        const ticketId = localStorage.getItem('ticket_id');
        if (ticketId) {
            const response = await fetchTicket(ticketId);
            if (response?.id) {
                setCurrentTicket(response);
            }
        }
    };

    useEffect(() => {
        fetchCurrentTicket();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentTicket) {
            history.push({
                pathname: `/tickets/${currentTicket.id}`,
                state: {
                    openModal: true,
                }
            });
        }
    }, [history, currentTicket]);

    const fetchData = async () => {
        let parameters = { q: searchTerm };
        if (asset?.id) {
            parameters['asset_id'] = asset.id;
        }
        if (zipcode?.id) {
            parameters['zipcode'] = zipcode?.id;
        }
        if (priority) {
            parameters['priority'] = priority;
        }
        if (category) {
            parameters['category'] = category;
        }
        if (seeAll) {
            parameters['see_all'] = seeAll;
        }
        if (forToday) {
            parameters['for_today'] = forToday;
        }

        setIsFetching(true);
        const response = await fetchTickets(parameters) || [];
        setIsFetching(false);

        setTickets([...response]);

        dispatch(updateFilters({ q: searchTerm, priority, category, asset, see_all: seeAll, for_today: forToday }));
    };

    useEffect(() => {
        let isMounted = true;

        const fetchLastVisit = async () => {
            const response = await fetchLastTechnicianVisit();
            if (isMounted && ['in_progress', 'paused'].includes(response?.status)) {
                setAsset(response.asset);
                const now = DateTime.now().toUTC();
                const checkInDate = DateTime.fromISO(response.check_in_at, { zone: 'utc' });
                const diff = now.ts - checkInDate.ts;
                let offset = now.ts + diff - response.pause_duration * 1000;

                if (response.is_paused) {
                    const lastPausedDate = DateTime.fromISO(response.last_paused_at, { zone: 'utc' });
                    const pauseDuration = (now.ts - lastPausedDate.ts);
                    setIsPaused(response.is_paused);
                    offset -= pauseDuration;
                }
                else {
                    setIsTimerRunning(true);
                }
                setOffsetTimer(offset);
            }
            if (isMounted) {
                setTimerReady(true);
            }
        };

        fetchLastVisit();

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const timerId = setTimeout(() => {
            fetchData();
        }, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [searchTerm, asset, zipcode, priority, category, seeAll, forToday]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleChangePriority = (e) => {
        setPriority(e.target.value);
    };

    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
    };

    const handleChangeAsset = (e, value) => {
        setAsset(value);
    };

    const handleChangeZipcode = (e, value) => {
        setZipcode(value);
    };

    const handleChangeSeeAll = () => {
        setSeeAll(state => !state);
    };

    const handleChangeForToday = () => {
        setForToday(state => !state);
    };

    const handleCheckIn = async () => {
        await checkIn({ asset_id: asset.id });
    };

    const handleCheckOut = async () => {
        await checkOut();
        setOffsetTimer(null);
    };

    const handlePause = async () => {
        await pauseTechnicianVisit();
    };

    const handleLogOut = (e) => {
        e.preventDefault();
        dispatch(authLogout());
    };

    return (
        <Container maxWidth="sm">
            <Box py={3}>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Box display="flex">
                        <Link mr={2} href="/" underline="none">
                            <Button color="primary" variant="outlined" size="large">{t('Home')}</Button>
                        </Link>
                        <Button color="error" variant="outlined" size="large" onClick={handleLogOut}>{t('Déconnexion')}</Button>
                    </Box>
                    <LanguageSwitcher />
                </Box>
                <Box mb={2}>
                    <TextField
                        value={searchTerm}
                        variant="outlined"
                        onChange={handleChangeSearchTerm}
                        fullWidth
                        InputProps={{
                            startAdornment: <SearchIcon />
                        }}
                    />
                </Box>
                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <PriorityInput value={priority} onChange={handleChangePriority} />
                    <CategoryInput value={category} onChange={handleChangeCategory} />
                </Box>
                <Box my={2} sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <AssetAutocomplete value={asset} onChange={handleChangeAsset} disabled={isTimerRunning || isPaused} />
                    <ZipcodeAutocomplete value={zipcode} onChange={handleChangeZipcode} />
                </Box>
                <Box sx={{ display: 'flex', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <FormControlLabel
                        value="see_all"
                        control={<Switch color="primary" checked={seeAll} onChange={handleChangeSeeAll} />}
                        label={t('Tous les tickets')}
                    />
                    <FormControlLabel
                        value="for_today"
                        control={<Switch color="primary" checked={forToday} onChange={handleChangeForToday} />}
                        label={t('Tickets du jour')}
                    />
                </Box>
                {asset && (
                    <>
                        <Box py={3}>
                            <Card variant="outlined" sx={{ backgroundColor: '#F3F3F3' }}>
                                <CardContent>
                                    {asset.address && (
                                        <Box mb={1}>
                                            <Typography><b>{t('Adresse')}</b></Typography>
                                            {(asset.latitude && asset.longitude) ? (
                                                <Link href={`https://maps.google.com/?q=${asset.latitude},${asset.longitude}`} underline="none" target="_blank" rel="noopener">{asset.address}</Link>
                                            ) : (
                                                <Typography>{asset.address}</Typography>
                                            )}
                                        </Box>
                                    )}
                                    {asset.code_door_key_box && (
                                        <Box>
                                            <Typography><b>{t('Code de la boite à clefs')}</b></Typography>
                                            <Typography>{asset.code_door_key_box}</Typography>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Box>
                        {timerReady && (
                            <Timer
                                onStart={handleCheckIn}
                                onStop={handleCheckOut}
                                onPause={handlePause}
                                isRunning={isTimerRunning}
                                setIsRunning={setIsTimerRunning}
                                isPaused={isPaused}
                                setIsPaused={setIsPaused}
                                textStart="Check in"
                                textStop="Check out"
                                offsetTimestamp={offsetTimer}
                            />
                        )}
                    </>
                )}
                <Box mt={3}>
                    <Button variant="contained" size="large" component={RouterLink} to={`/tickets/create`} fullWidth>{t('Créer un nouveau ticket')}</Button>
                </Box>
                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>{t('Liste des tickets')} ({isFetching ? t('Mise à jour...') : tickets.length})</Typography>
                    {tickets.length === 0 ? (
                        <Typography variant="body1">{t('Pas de résultat')}</Typography>
                    ) : (
                        tickets.map(ticket => (
                            <Box mb={2} key={`ticket_${ticket.id}`}>
                                <TicketCard record={ticket} />
                            </Box>
                        ))
                    )}
                </Box>
            </Box>
        </Container>
    );
}

export default TicketList;