import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from 'components/common/PrivateRoute';
import Login from 'components/account/Login';
import TicketList from 'components/tickets/TicketList';
import TicketEdit from 'components/tickets/TicketEdit';
import TicketCreate from 'components/tickets/TicketCreate';

const App = () => (
    <React.Suspense fallback="Loading...">
        <Router>
            <Switch>
                <PrivateRoute exact path="/">
                    <TicketList />
                </PrivateRoute>
                <PrivateRoute exact path="/tickets/create">
                    <TicketCreate />
                </PrivateRoute>
                <PrivateRoute exact path="/tickets/:id">
                    <TicketEdit />
                </PrivateRoute>
                <Route exact path="/login">
                    <Login />
                </Route>
            </Switch>
        </Router>
    </React.Suspense>
);

export default App;
