import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';


const PieceInput = ({ required, ...props }) => {
    const { t } = useTranslation();

    const choices = [
        { id: 'kitchen', name: t('Cuisine') },
        { id: 'living_room', name: t('Salon') },
        { id: 'bathroom', name: t('Salle de bain') },
        { id: 'utility_room', name: t('Buanderie') },
        { id: 'garden', name: t('Jardin') },
        { id: 'dining_room', name: t('Salle à manger') },
        { id: 'bedroom', name: t('Chambre') },
        { id: 'hall', name: t('Hall') },
        { id: 'others', name: t('Autres') }
    ];

    return (
        <FormControl required={required} fullWidth>
            <InputLabel id="piece">{t('Pièce')}</InputLabel>
            <Select label="Pièce" labelId="piece" {...props}>
                <MenuItem value="">&nbsp;</MenuItem>
                {choices?.map(p => (
                    <MenuItem value={p.id} key={`piece_${p.id}`}>{p.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PieceInput;