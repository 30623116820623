import axios from 'axios';
import qs from 'qs';
import { API_URL } from './settings';


axios.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token');
		if(token) {
			config.headers['Authorization'] = token;
		}

		const lang = localStorage.getItem('i18nextLng');
		if(lang) {
			config.params = { ...config.params, lang };
		}

		return config;
	},
	error => {
		Promise.reject(error)
	}
);

/* Account */
export const login = async (data) => {
	const response = await axios.post(`${API_URL}/account/login`, data);
	return response.data;
}

export const forgotPassword = async (data) => {
	const response = await axios.post(`${API_URL}/account/forget_password`, data);
	return response.data;
};

export const resetPassword = async (data) => {
	const response = await axios.post(`${API_URL}/account/reset_password`, data);
	return response.data;
};

export const fetchCurrentUser = async () => {
	try {
		const response = await axios.get(`${API_URL}/account`);
		return response.data;
	}
	catch (err) {
		localStorage.removeItem('token');
	}
};

export const updateAccount = async (data) => {
	const response = await axios.put(`${API_URL}/account`, data);
	return await response.data;
};

/* Tickets */
export const fetchTickets = async (data) => {
	const queryData = { ...data };
	const query = queryData ? `?${qs.stringify(queryData, { arrayFormat: 'comma', encode: false })}` : '';
	const response = await axios.get(`${API_URL}/support/tickets${query}`);
	return response.data.results;
};

export const fetchTicket = async (id) => {
	const response = await axios.get(`${API_URL}/support/tickets/${id}`);
	return response.data;
};

export const updateTicket = async (id, data) => {
	const response = await axios.put(`${API_URL}/support/tickets/${id}`, data);
	return await response.data;
};

export const addTicket = async (data) => {
	const response = await axios.post(`${API_URL}/support/tickets`, data);
	return await response.data;
};

export const addTicketPicture = async (id, file) => {
	const formData = new FormData();
	formData.append('picture_file', file);
	const response = await axios.post(`${API_URL}/support/tickets/${id}/picture`, formData, { headers: { 'content-type': 'multipart/form-data' } });
	return await response.data;
};

export const addTicketVideo = async (id, file) => {
	const formData = new FormData();
	formData.append('video_file', file);
	const response = await axios.post(`${API_URL}/support/tickets/${id}/video`, formData, { headers: { 'content-type': 'multipart/form-data' } });
	return await response.data;
};

export const markTicketUnresolved = async (id) => {
	const response = await axios.post(`${API_URL}/support/tickets/${id}/mark_unresolved`);
	return await response.data;
};

export const markTicketToControl = async (id) => {
	const response = await axios.post(`${API_URL}/support/tickets/${id}/mark_to_control`);
	return await response.data;
};

/* Constants */
export const fetchTicketPriorities = async () => {
	const response = await axios.get(`${API_URL}/support/tickets/priorities`);
	return response.data.priorities;
};

export const fetchTicketCategories = async () => {
	const response = await axios.get(`${API_URL}/support/tickets/categories`);
	return response.data.categories;
};

export const fetchTicketFloors = async () => {
	const response = await axios.get(`${API_URL}/support/tickets/floors`);
	return response.data.floors;
};

/* Assets */
export const fetchAssets = async (data) => {
	const queryData = { ...data };
	const query = queryData ? `?${qs.stringify(queryData, { arrayFormat: 'comma', encode: false })}` : '';
	const response = await axios.get(`${API_URL}/support/assets${query}`);
	return response.data.assets;
};

export const fetchZipcodes = async () => {
	const response = await axios.get(`${API_URL}/support/zipcodes`);
	return response.data.zipcodes;
};

/* Technician visits */
export const checkIn = async (data) => {
	const response = await axios.post(`${API_URL}/support/technician_visits/check_in`, data);
	return await response.data;
};

export const checkOut = async (data) => {
	const response = await axios.post(`${API_URL}/support/technician_visits/check_out`);
	return await response.data;
};

export const pauseTechnicianVisit = async (data) => {
	const response = await axios.post(`${API_URL}/support/technician_visits/pause`);
	return await response.data;
};

export const fetchLastTechnicianVisit = async () => {
	const response = await axios.get(`${API_URL}/support/technician_visits/last`);
	return response.data;
};

/* Ticket Materials */
export const addTicketMaterial = async (ticketId, data) => {
	const response = await axios.post(`${API_URL}/support/tickets/${ticketId}/materials/`, data);
	return await response.data;
};

export const deleteTicketMaterial = async (ticketId, materialId) => {
	const response = await axios.delete(`${API_URL}/support/tickets/${ticketId}/materials/${materialId}`);
	return await response.data;
};
