import * as services from 'services/api';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CHECK_AUTH = 'CHECK_AUTH';
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const AUTH_ERROR = 'AUTH_ERROR';

export const authLogin = (data) => async dispatch => {
	try {
		const result = await services.login(data);
		if(result.success) {
			localStorage.setItem('token', result.token);
			dispatch(authLoginAsync({ isAuthenticated: true, token: result.token }));
		}
		else {
			dispatch(authLogout());
		}
	}
	catch (error) {
		localStorage.removeItem('token');
		dispatch({
			type: AUTH_ERROR,
			payload: { errors: error?.response?.data?.errors }
		});
	}
};

const authLoginAsync = auth => ({
	type: LOGIN,
	payload: auth
});

export const authLogout = () => {
	localStorage.removeItem('token');

	return {
		type: LOGOUT,
		payload: null
	}
};

export const checkAuth = () => {
	const token = localStorage.getItem('token') || null;

	return {
		type: CHECK_AUTH,
		payload: { isAuthenticated: !!token, token }
	}
};

export const fetchCurrentUser = () => async dispatch => {
	const result = await services.fetchCurrentUser();
	if(result?.id) {
		dispatch(fetchCurrentUserAsync(result));
	}
	else {
		dispatch(authLogout());
	}
};

const fetchCurrentUserAsync = user => ({
	type: FETCH_CURRENT_USER,
	payload: user
});

export const updateCurrentUser = (data) => async dispatch => {
	const result = await services.updateAccount(data);
	dispatch(updateCurrentUserAsync(result));
};

const updateCurrentUserAsync = user => ({
	type: UPDATE_CURRENT_USER,
	payload: user
});