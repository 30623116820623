import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardActionArea, Typography, Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

import PriorityField from './fields/PriorityField';

const LabelContainer = styled(Box)({
    display: 'flex'
});

const Label = styled(Typography)({
    minWidth: '50%'
});

const ResolvedIcon = styled(CheckIcon)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: '#FFF',
    padding: '3px',
    borderRadius: '50%'
}));

const ChipField = styled(Chip)({
    marginLeft: 1,
    borderRadius: 1,
    textTransform: 'uppercase',
    fontWeight: 'bold'
});

const TicketCard = ({ record }) => {
    const { t } = useTranslation();

    return (
        <Card variant="outlined">
            <CardActionArea component={RouterLink} to={`/tickets/${record.id}`}>
                <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                        <Typography><b>{record.asset ? record.asset.name : 'Ticket'} n°{record.id}</b></Typography>
                        <Box display="flex" alignItems="center">
                            {['resolved', 'to_control'].includes(record.ticket_state?.code) && (
                                <Box mr={1} display="flex" justifyContent="center">
                                    <ResolvedIcon />
                                </Box>
                            )}
                            {record?.worker_id && <ChipField label={t('Assigné')} color="primary" />}
                        </Box>
                    </Box>
                    <LabelContainer>
                        <Label>{t('Catégorie')}:</Label>
                        <Typography>{record?.maintenance_category_value}</Typography>
                    </LabelContainer>
                    <LabelContainer>
                        <Label>{t('Priorité')}:</Label>
                        <PriorityField record={record} />
                    </LabelContainer>
                    <LabelContainer>
                        <Label>{t('Étage')}:</Label>
                        <Typography>{record?.floor_value}</Typography>
                    </LabelContainer>
                    <LabelContainer>
                        <Label>{t('Pièce concernée')}:</Label>
                        <Typography>{record?.piece_value}</Typography>
                    </LabelContainer>
                    <LabelContainer>
                        <Label>{t("Date d'ouverture")}:</Label>
                        <Typography>{new Date(record?.created_at).toLocaleDateString('fr-FR', { year: 'numeric', month: 'numeric', day: 'numeric' })}</Typography>
                    </LabelContainer>
                    <LabelContainer>
                        <Label>{t("Date d'intervention")}:</Label>
                        <Typography>{record?.formatted_technician_range_time}</Typography>
                    </LabelContainer>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default TicketCard;