import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Button } from '@mui/material';
import PlayIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import { useStopwatch } from 'react-timer-hook';

import Modal from './Modal';

const Timer = ({ onStart, onStop, onPause, setIsRunning, isPaused, setIsPaused, textStart, textStop, offsetTimestamp, ...props }) => {
    const [openStartModal, setOpenStartModal] = useState(false);
    const [openStopModal, setOpenStopModal] = useState(false);
    const { t } = useTranslation();
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        pause,
        start,
        reset
    } = useStopwatch({ offsetTimestamp, autoStart: props.isRunning });

    useEffect(() => {
        setIsRunning(isRunning);
    }, [isRunning, setIsRunning]);

    const handleStart = () => {
        if (onStart) {
            onStart();
        }

        reset();
        handleCloseStartModal()
    };

    const handleStop = () => {
        if (onStop) {
            onStop();
        }

        reset();
        pause();
        handleCloseStopModal();
        setIsPaused(false);
    };

    const handlePause = () => {
        if (onPause) {
            onPause();
        }
        pause();
        setIsPaused(true);
    };

    const handleResume = () => {
        if (onPause) {
            onPause();
        }
        start();
        setIsPaused(false);
    };

    const handleOpenStartModal = () => {
        setOpenStartModal(true);
    };

    const handleCloseStartModal = () => {
        setOpenStartModal(false);
    };

    const handleOpenStopModal = () => {
        setOpenStopModal(true);
    };

    const handleCloseStopModal = () => {
        setOpenStopModal(false);
    };

    return (
        <Box pb={3} sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
            {(isRunning || isPaused) ? (
                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: '2fr 1fr' }}>
                    <Button variant="contained" size="large" color="error" onClick={handleOpenStopModal}><StopIcon />{textStop}</Button>
                    {isPaused ? (
                        <Button variant="contained" size="large" color="success" onClick={handleResume}><PlayIcon /></Button>
                    ) : (
                        <Button variant="contained" size="large" color="warning" onClick={handlePause}><PauseIcon /></Button>
                    )}
                </Box>
            ) : (
                <Button variant="contained" size="large" color="success" onClick={handleOpenStartModal}><PlayIcon />{textStart}</Button>
            )}
            <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>{String(days * 24 + hours).padStart(2, '0')}</span>:<span>{String(minutes).padStart(2, '0')}</span>:<span>{String(seconds).padStart(2, '0')}</span>
            </Card>

            <Modal open={openStartModal} onClose={handleCloseStartModal}>
                <h4>{t('As-tu payé ton stationnement?')}</h4>
                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <Button variant="outlined" onClick={handleCloseStartModal}>{t('Annuler')}</Button>
                    <Button variant="contained" onClick={handleStart}>{t('OK')}</Button>
                </Box>
            </Modal>
            <Modal open={openStopModal} onClose={handleCloseStopModal}>
                <h4>{t('Veux-tu check out?')}</h4>
                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <Button variant="outlined" onClick={handleCloseStopModal}>{t('Annuler')}</Button>
                    <Button variant="contained" onClick={handleStop}>{t('OK')}</Button>
                </Box>
            </Modal>
        </Box>
    );
};

Timer.defaultProps = {
    textStart: '',
    textStop: '',
    offsetTimestamp: null
};

export default Timer;