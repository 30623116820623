import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Toolbar, Button, Container, Box, Typography, TextField, FormControlLabel, Switch } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';

import AssetAutocomplete from 'components/assets/AssetAutocomplete';
import Dropzone from 'components/common/Dropzone';
import FloorNumberInput from 'components/tickets/inputs/FloorNumberInput';
import PieceInput from 'components/tickets/inputs/PieceInput';
import CategoryInput from 'components/tickets/inputs/CategoryInput';
import PriorityInput from 'components/tickets/inputs/PriorityInput';

import { addTicket, addTicketPicture, addTicketVideo } from 'services/api';

const BackButton = styled(Button)({
    color: '#000',
    border: 'none',
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
        border: 'none'
    },
    '& svg': {
        backgroundColor: '#000',
        color: '#FFF',
        borderRadius: '50%',
        padding: '2px'
    }
});

const TicketCreate = () => {
    const [floorNumber, setFloorNumber] = useState('');
    const [category, setCategory] = useState('');
    const [priority, setPriority] = useState('normal');
    const [piece, setPiece] = useState('');
    const [asset, setAsset] = useState({});
    const [description, setDescription] = useState('');
    const [documents, setDocuments] = useState([]);
    const [todoToday, setTodoToday] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();

    const handleChangeFloorNumber = (e) => {
        setFloorNumber(e.target.value);
    };

    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
    };

    const handleChangePriority = (e) => {
        setPriority(e.target.value);
    };

    const handleChangePiece = (e) => {
        setPiece(e.target.value);
    };

    const handleChangeDescription = (e) => {
        setDescription(e.target.value);
    };

    const handleChangeAsset = (e, value) => {
        setAsset(value);
    };

    const handleChangeTodoToday = () => {
        setTodoToday(state => !state);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await addTicket({
            asset_id: asset?.id,
            todo_today: todoToday,
            floor_number: floorNumber,
            maintenance_category: category,
            priority,
            piece,
            description
        });

        if (response.id) {
            documents.forEach(async document => {
                if (document.type.startsWith('video/')) {
                    await addTicketVideo(response.id, document);
                }
                else {
                    await addTicketPicture(response.id, document);
                }
            });
            history.push('/');
        }
    };

    return (
        <>
            <Toolbar>
                <BackButton
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    component={RouterLink}
                    to="/"
                >
                    {t('Retour')}
                </BackButton>
            </Toolbar>
            <Container maxWidth="sm">
                <Box py={3} component="form" onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <Typography variant="h5" component="h1" gutterBottom>{t('Nouveau ticket')}</Typography>
                    </Box>
                    <Box mb={2}>
                        <AssetAutocomplete value={asset} onChange={handleChangeAsset} seeAll required />
                    </Box>
                    <Box mb={2}>
                        <FloorNumberInput value={floorNumber} onChange={handleChangeFloorNumber} required />
                    </Box>
                    <Box mb={2}>
                        <PieceInput value={piece} onChange={handleChangePiece} required />
                    </Box>
                    <Box mb={2}>
                        <CategoryInput value={category} onChange={handleChangeCategory} required />
                    </Box>
                    <Box mb={2}>
                        <PriorityInput value={priority} onChange={handleChangePriority} required />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label={t('Résumé')}
                            multiline
                            rows={4}
                            fullWidth
                            value={description}
                            onChange={handleChangeDescription}
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <FormControlLabel
                            value="todo_today"
                            control={<Switch color="primary" checked={todoToday} onChange={handleChangeTodoToday} />}
                            label={t("Je le fais aujourd'hui")}
                        />
                    </Box>
                    <Box mb={1}>
                        <Dropzone files={documents} setFiles={setDocuments} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={5}>
                        <Button type="submit" variant="contained" size="large">{t('Créer')}</Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default TicketCreate;
