import { createTheme } from '@mui/material/styles';
import variables from './variables';

const theme = createTheme({
	palette: {
		primary: {
			main: variables.colors.primary
		},
		success: {
			main: variables.colors.green,
			contrastText: '#FFF'
		},
		error: {
			main: variables.colors.red,
			contrastText: '#FFF'
		},
		warning: {
			main: variables.colors.orange,
			contrastText: '#000'
		},
		green: {
			main: variables.colors.lightGreen,
			contrastText: '#000'
		},
		white: {
			main: variables.colors.white,
			contrastText: '#000'
		},
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				h6: {
					fontWeight: 'bold'
				}
			}
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					'& svg': {
						fontSize: '35px',
						backgroundColor: '#FFF',
						padding: '1px',
						borderRadius: '50%',
					},
				}
			}
		}
	}
});

export default theme;
