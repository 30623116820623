import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { fetchCurrentUser } from 'actions/auth';


// TODO improve this
const PrivateRoute = ({ children, ...rest }) => {
	const auth = useSelector(state => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!auth?.isAuthenticated || !auth.currentUser) {
			dispatch(fetchCurrentUser());
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Route {...rest} render={({ location }) => (
			auth.isAuthenticated === true || localStorage.getItem('token')
				? children
				: <Redirect to={{
					pathname: '/login',
					state: { from: location }
				}} />
		)} />
	);
};

export default PrivateRoute;