import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchZipcodes } from 'services/api';

const ZipcodeAutocomplete = ({ value, ...props }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const { t } = useTranslation();
    const loading = open && options.length === 0;

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            const zipcodes = await fetchZipcodes() || [];
            if (isMounted) {
                setOptions([...zipcodes]);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleChangeSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Autocomplete
            value={value}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, val) => option?.id === val?.id}
            getOptionLabel={(option) => option.name || ''}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={searchTerm}
                    onChange={handleChangeSearchTerm}
                    label={t('Ville')}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            {...props}
        />
    );
};

export default ZipcodeAutocomplete;