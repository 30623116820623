import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { deleteTicketMaterial } from 'services/api';
import Modal from 'components/common/Modal';

const DeleteMaterialButton = ({ record, ticket, updateTicket }) => {
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const { t } = useTranslation();

	const handleConfirm = async () => {
        handleCloseModal();
		const response = await deleteTicketMaterial(record.ticket_id, record.id);

		if (response.id) {
			const updatedTicket = { ...ticket };
			const index = updatedTicket.materials.findIndex(l => l.id === record.id);
			updatedTicket.materials.splice(index, 1);
			updateTicket(updatedTicket);
		}
	};

    if (!ticket) return null;
	if (ticket.ticket_state?.code === 'resolved') return null

	return (
		<>
			<IconButton aria-label="delete" size="small" onClick={handleOpenModal}>
				<DeleteIcon color="error" />
			</IconButton>
            <Modal open={openModal} onClose={handleCloseModal}>
                <h4>{t('Supprimer le matériel du ticket?')}</h4>
                <Box mb={4}>
					{t('Voulez-vous supprimer le matériel {{description}}', { description: record.description })}
                </Box>
                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <Button variant="outlined" onClick={handleCloseModal}>{t('Annuler')}</Button>
                    <Button variant="contained" onClick={handleConfirm}>{t('Confirmer')}</Button>
                </Box>
            </Modal>
		</>
	);
};

export default DeleteMaterialButton;
