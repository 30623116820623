import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

import DeleteMaterialButton from './DeleteMaterialButton';

const TicketMaterialList = ({ ticket, updateTicket }) => {
    const { t } = useTranslation();

    if (!ticket.materials || ticket.materials.length === 0) return null;

    return (
        <TableContainer component={Paper} sx={{ marginBottom: 1 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('Description')}</TableCell>
                        <TableCell align="right">{t('Montant HTVA')}</TableCell>
                        <TableCell>{t('Document')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ticket.materials.map((material) => (
                        <TableRow
                            key={material.id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {material.description}
                            </TableCell>
                            <TableCell align="right">{material.amount_untaxed?.toFixed(2)} €</TableCell>
                            <TableCell>
                                {material.document_url && (
                                    <IconButton component="a" href={material.document_url}>
                                        <GetAppIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                            <TableCell>
                                <DeleteMaterialButton record={material} ticket={ticket} updateTicket={updateTicket} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TicketMaterialList;
