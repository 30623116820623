export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export const updateFilters = filters => ({
    type: UPDATE_FILTERS,
    payload: filters
});

export const resetFilters = () => ({
    type: RESET_FILTERS,
    payload: null
});
