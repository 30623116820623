import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

import { fetchTicketFloors } from 'services/api';

const FloorNumberInput = (props) => {
    const [floors, setFloors] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchFloors = async () => {
            const response = await fetchTicketFloors() || [];
            setFloors([...response]);
        };

        fetchFloors();
    }, [i18n.language]);

    return (
        <FormControl fullWidth>
            <InputLabel id="floor_number">{t('Étage')}</InputLabel>
            <Select label="Etage" labelId="floor_number" {...props}>
                <MenuItem value="">&nbsp;</MenuItem>
                {floors?.map(p => (
                    <MenuItem value={p.id} key={`floor_number_${p.id}`}>{p.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FloorNumberInput;