import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { fetchTicketPriorities } from 'services/api';

const PriorityInput = ({ value, required, ...props }) => {
    const [priorities, setPriorities] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let isMounted = true;

        const fetchPriorities = async () => {
            const response = await fetchTicketPriorities() || [];
            if (isMounted) {
                setPriorities([...response]);
            }
        };

        fetchPriorities();

        return () => {
            isMounted = false;
        };
    }, [i18n.language]);

    return (
        <FormControl required={required} fullWidth>
            <InputLabel id="priority">{t('Priorité')}</InputLabel>
            <Select label="Priorité" labelId="priority" value={priorities?.length > 0 ? value : ''} {...props}>
                <MenuItem value="">&nbsp;</MenuItem>
                {priorities?.map(p => (
                    <MenuItem value={p.id} key={`priority_${p.id}`}>{p.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PriorityInput;