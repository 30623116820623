import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		defaultLocale: 'fr',
		locales: ['fr', 'en', 'nl'],
		ns: ['common'],
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json'
		},
		fallbackLng: 'fr',
		keySeparator: false,
		nsSeparator: false,
		interpolation: {
			escapeValue: true
		},
		strictMode: false,
		returnEmptyString: false,
		shallowRender: true,
});

export default i18n;