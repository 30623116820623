const variables = {
	colors: {
		primary: '#64C8D1',
		green: '#54D219',
		lightGreen: '#B4E2B7',
		red: '#E74C3C',
		orange: '#FFE195',
		white: '#FFF',
	}
};

export default variables;
