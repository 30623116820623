import { LOGIN, LOGOUT, AUTH_ERROR, CHECK_AUTH, FETCH_CURRENT_USER, UPDATE_CURRENT_USER } from 'actions/auth';

const initialState = {
	isAuthenticated: false,
	token: null,
	currentUser: null,
	errors: null
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return action.payload;
		case LOGOUT:
			return initialState;
		case AUTH_ERROR:
			return { ...initialState, ...action.payload };
		case CHECK_AUTH:
			return action.payload;
		case FETCH_CURRENT_USER:
			return { ...state, currentUser: action.payload, isAuthenticated: true };
		case UPDATE_CURRENT_USER:
			return { ...state, currentUser: action.payload };
		default:
			return state;
	}
};

export default auth;