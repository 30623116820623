import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Box, Button, TextField, Typography } from '@mui/material';

import { authLogin } from 'actions/auth';


const Login = () => {
	const auth = useSelector(state => state.auth);
	const history = useHistory();
	const dispatch = useDispatch();
    const { t } = useTranslation();

	const [state, setState] = useState({
		'email': '',
		'password': '',
	});

	useEffect(() => {
		if(auth.isAuthenticated) {
			history.push('/');
		}
	}, [auth.isAuthenticated, history]);

	const handleChange = (event) => {
		const name = event.target.name;

		setState({
			...state,
			[name]: event.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (state.email && state.password) {
			await dispatch(authLogin({ login: state.email, password: state.password }));
		}
	};

	return (
        <Container>
            <Box component="form" onSubmit={handleSubmit} autoComplete="off" m={2} display="flex" flexDirection="column" alignItems="center">
                <Box width="100%" display="flex" justifyContent="center" mb={3}>
                    <img src="/img/logo.svg" alt="" />
                    
                </Box>
                <Typography variant="h4" component="h1" mt={2} mb={5}>Maintenance</Typography>
                <Box mb={2}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        value={state.email}
                        variant="outlined"
                        type="email"
                        onChange={handleChange}
                    />
                </Box>
                <Box mb={3}>
                    <TextField
                        required
                        id="password"
                        name="password"
                        label="Password"
                        value={state.password}
                        variant="outlined"
                        type="password"
                        onChange={handleChange}
                    />
                    {auth?.errors?.login && (
                        <Typography variant="body2" color="error" align="center">{auth.errors.login}</Typography>
                    )}
                </Box>
                <Button type="submit" variant="contained" color="primary">{t('Connexion')}</Button>
            </Box>
        </Container>
	);
};

export default Login;