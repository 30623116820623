import React from 'react';
import { Chip } from '@mui/material';

const PriorityField = ({ record, source, ...props }) => {
    if (!record) return null;

    const priority = record[source];
    let color;
    switch (record?.priority) {
        /* TODO: check if good colors and add priorities if necessary */
        case 'low':
            break;
        case 'normal':
            color = 'green'
            break;
        case 'high':
            color = 'warning'
            break;
        case 'urgent':
            color = 'error'
            break;
        default:
            break;
    }

    if (!priority) {
        return null;
    }

    return (
        <Chip {...props} label={priority} color={color} />
    );
};

PriorityField.defaultProps = {
    source: 'priority_value',
};

export default PriorityField;
