import { Box, Modal } from '@mui/material';

const CustomModal = ({ children, ...props }) => (
    <Modal {...props}>
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                p: 4,
                textAlign: 'center',
                width: '80%',
                maxWidth: 500,
                borderRadius: '24px'
            }}
        >
            {children}
        </Box>
    </Modal>
);

export default CustomModal;