import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next';
import { Box, Typography, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, FormControl } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';

import { addTicketMaterial } from 'services/api';

const Container = styled(Box)({
    padding: '15px',
    border: '2px dashed rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    cursor: 'pointer'
});

const AddMaterialButton = ({ ticket, updateTicket }) => {
	const [showDialog, setShowDialog] = useState(false);
	const [file, setFile] = useState();
	const [form, setForm] = useState({});
	const [descriptionError, setDescriptionError] = useState('');
	const [amountUntaxedError, setAmountUntaxedError] = useState('');
	const { t } = useTranslation();
    const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		multiple: false,
        onDrop: (newFile) => setFile(newFile[0]),
    });

    const handleDelete = () => setFile(null);
	const handleOpenDialog = () => setShowDialog(true);
	const handleCloseDialog = () => {
		setShowDialog(false);
		setForm({});
		setDescriptionError('');
		setAmountUntaxedError('');
		setFile(null);
	};
	const handleChangeDescription = (e) => {
		setForm(state => ({ ...state, [e.target.name]: e.target.value }));
		if (!e.target.value) {
			setDescriptionError(t('Ce champ est requis'));
		} else {
			setDescriptionError('');
		}
	};

    const handleChangeAmountUntaxed = (e) => {
        setForm(state => ({ ...state, [e.target.name]: e.target.value }));
		if (!e.target.value  || parseFloat(e.target.value) === 0) {
			setAmountUntaxedError(t('Ce champ est requis'));
		} else if (parseFloat(e.target.value) < 0) {
			setAmountUntaxedError(t('Minimum 0'));
		} else {
			setAmountUntaxedError('');
		}
    };

    const handleSubmit = async () => {
		if (!form.description) {
			setDescriptionError(t('Ce champ est requis'));
			return
		}
		if (!form.amount_untaxed) {
			setAmountUntaxedError(t('Ce champ est requis'));
			return
		}

        const formData = new FormData();
        formData.append('description', form.description);
        formData.append('amount_untaxed', form.amount_untaxed);
        formData.append('material_file', file);

		const response = await addTicketMaterial(ticket.id, formData);
		if (response.id) {
			const updatedTicket = { ...ticket };
            if (!updatedTicket.materials) updatedTicket.materials = [];
			updatedTicket.materials.push(response);
			updateTicket(updatedTicket);
            setShowDialog(false);
		}
    };

	if (ticket.ticket_state?.code === 'resolved') return null

	return (
		<>
			<Button variant="outlined" startIcon={<AddIcon />} onClick={handleOpenDialog}>
				{t('Ajouter un matériel')}
			</Button>
			<Dialog open={showDialog} onClose={handleCloseDialog}>
				<DialogTitle>{t('Ajouter un matériel au ticket')}</DialogTitle>
				<FormControl>
					<DialogContent>
						<TextField
                            label={t('Description')}
							name="description"
							required
                            fullWidth
                            onChange={handleChangeDescription}
                            sx={{ marginBottom: 2 }}
							error={descriptionError !== ""}
							helperText={descriptionError}
                        />
						<TextField
							label={t('Montant HTVA')}
							name="amount_untaxed"
							type="number"
							required
                            fullWidth
                            onChange={handleChangeAmountUntaxed}
                            sx={{ marginBottom: 2 }}
							error={amountUntaxedError !== ""}
							helperText={amountUntaxedError}
                        />
						<Container {...getRootProps()}>
							<input {...getInputProps()} />
							<Typography sx={{ display: 'flex', alignItems: 'center' }}>
								<AddIcon /> {t("Facture, preuve d'achat, ticket de caisse")}
							</Typography>
						</Container>
						{file && (
							<>
								{file.name}
								<IconButton onClick={handleDelete} size="small">
									<DeleteIcon color="error" />
								</IconButton>
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							onClick={handleSubmit}
							disabled={!form.description && !form.amount_untaxed}
							startIcon={<SaveIcon />}
						>
							{t('Enregistrer')}
						</Button>
						<IconButton onClick={handleCloseDialog} size="small" color='primary'>
							<CancelIcon />
						</IconButton>
					</DialogActions>
				</FormControl>
			</Dialog>
		</>
	);
};

export default AddMaterialButton;
