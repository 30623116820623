import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { fetchTicketCategories } from 'services/api';

const CategoryInput = (props) => {
    const [categories, setCategories] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let isMounted = true;

        const fetchCategories = async () => {
            const response = await fetchTicketCategories() || [];
            if (isMounted) {
                setCategories([...response]);
            }
        };

        fetchCategories();

        return () => {
            isMounted = false;
        };
    }, [i18n.language]);

    return (
        <FormControl fullWidth>
            <InputLabel id="category">{t('Catégorie')}</InputLabel>
            <Select label="Catégorie" labelId="category" {...props}>
                <MenuItem value="">&nbsp;</MenuItem>
                {categories?.map(p => (
                    <MenuItem value={p.id} key={`category_${p.id}`}>{p.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CategoryInput;