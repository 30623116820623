import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { fetchAssets } from 'services/api';

const AssetAutocomplete = ({ value, seeAll, required, ...props }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const { t } = useTranslation();
    const loading = open && options.length === 0;

    const fetchData = async () => {
        const assets = await fetchAssets({ q: searchTerm, see_all: seeAll }) || [];
        setOptions([...assets]);
    };
  
    useEffect(() => {
        if (!loading) {
            return undefined;
        }

        fetchData();
    }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

	useEffect(() => {
		const timerId = setTimeout(() => {
			fetchData();
		}, 1000);

		return () => {
			clearTimeout(timerId);
		};
	}, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleChangeSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Autocomplete
            value={value}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            getOptionLabel={(option) => option.name || ''}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={searchTerm}
                    onChange={handleChangeSearchTerm}
                    label={t('Maison')}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            {...props}
        />
    );
};

export default AssetAutocomplete;