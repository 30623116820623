import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Toolbar, Button, Container, Box, FormControl, FormControlLabel, RadioGroup, Radio, Typography, Card, CardContent, TextField, Chip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';

import Dropzone from 'components/common/Dropzone';
import Gallery from 'components/common/Gallery';
import Divider from 'components/common/Divider';
import Modal from 'components/common/Modal';
import TicketMaterials from './TicketMaterials';
import PriorityField from './fields/PriorityField';
import LanguageSwitcher from 'components/common/LanguageSwitcher.js';

import { fetchTicket, updateTicket, addTicketPicture, addTicketVideo, markTicketToControl, markTicketUnresolved } from 'services/api';

const BackButton = styled(Button)({
    color: '#000',
    border: 'none',
    textTransform: 'none',
    fontWeight: 'bold',
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
        border: 'none'
    },
    '& svg': {
        backgroundColor: '#000',
        color: '#FFF',
        borderRadius: '50%',
        padding: '2px'
    }
});

const LabelContainer = styled(Box)({
    display: 'flex'
});

const Label = styled(Typography)({
    minWidth: '50%'
});

const ResolvedIcon = styled(CheckIcon)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white.main,
    padding: '3px',
    borderRadius: '50%'
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    width: '40%',
    borderRadius: '6px',
    marginLeft: '16px',
    padding: '1px',
    color: theme.palette.white.main,
}));

const StyledRadioGroup = styled(RadioGroup)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 2,
});

const ChipField = styled(Chip)({
    marginLeft: 1,
    borderRadius: 1,
    textTransform: 'uppercase',
    fontWeight: 'bold'
});

const TicketEdit = () => {
    const [record, setRecord] = useState();
    const [ticketState, setTicketState] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [resumeTechnician, setResumeTechnician] = useState('');
    const [technicianPrice, setTechnicianPrice] = useState('');
    const [technicianPriceError, setTechnicianPriceError] = useState('');
    const [documents, setDocuments] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [validationError, setValidationError] = useState('');
    const ref = useRef(null);
    const { id } = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const { state } = useLocation();

    useEffect(() => {
        if (record) localStorage.setItem('ticket_id', record.id);
    }, [record]);

    useEffect(() => {
        if (state?.openModal) setOpenModal(true);
    }, [state]);

    const fetchData = async () => {
        const response = await fetchTicket(id);

        if (response?.id) {
            setRecord(response);
            setResumeTechnician(response.resume_technician || '');
            setTechnicianPrice(response.technician_price);
            setTicketState(response.ticket_state?.code);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeTicketState = (e) => {
        setTicketState(e.target.value);
    };

    const handleChangeNotes = (e) => {
        setResumeTechnician(e.target.value);
    };

    const handleChangeTechnicianPrice = (e) => {
        setTechnicianPrice(e.target.value);
        if (!e.target.value) {
            setTechnicianPriceError(t('Ce champ est requis'));
        } else if (parseFloat(e.target.value) < 0) {
            setTechnicianPriceError(t('Minimum 0'));
        } else {
            setTechnicianPriceError('');
        }
    };

    const handleBack = () => {
        localStorage.removeItem('ticket_id');
        history.push('/');
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSaveButtonDisabled(true);
        setValidationError('');

        const hasTechnicianPicturesOrVideos = documents.length > 0 ||
        (record.technician_pictures && record.technician_pictures.length > 0) ||
        (record.technician_videos && record.technician_videos.length > 0);

        if (ticketState === 'to_control' && !hasTechnicianPicturesOrVideos) {
            setValidationError(t('Vous devez ajouter des photos ou des vidéos avant de valider le ticket'));
            setSaveButtonDisabled(false);
            return;
        }

        const updateData = { resume_technician: resumeTechnician };
        if (record.maintenance_type === 'lump_sum') {
            if (technicianPrice === null || technicianPrice === undefined || !technicianPrice.toString()) {
                setTechnicianPriceError(t('Ce champ est requis'));
                setSaveButtonDisabled(false);
                return;
            }
            updateData.technician_price = technicianPrice;
        }

        const response = await updateTicket(record.id, updateData);

        const documentTotal = documents.length;
        let documentCounter = 0;

        if (response.id) {
            if (ticketState === 'to_control') {
                await markTicketToControl(record.id);
            } else if (ticketState === 'unresolved') {
                await markTicketUnresolved(record.id);
            }

            for (const document of documents) {
                documentCounter += 1;
                setUploadProgress(Math.floor((documentCounter / documentTotal) * 100));
                if (document.type.startsWith('video/')) {
                    await addTicketVideo(record.id, document);
                } else {
                    await addTicketPicture(record.id, document);
                }
            }
            setSaveButtonDisabled(false);
            localStorage.removeItem('ticket_id');
            history.push('/');
        }
    };

    if (!record) {
        return null;
    }

    return (
        <>
            <Toolbar>
                <BackButton
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleOpenModal}
                >
                    {t('Retour')}
                </BackButton>
            </Toolbar>
            <Container maxWidth="sm">
                <Box py={3} sx={{ position: 'relative' }}>
                    <Box mb={2} display="flex" justifyContent="flex-end">
                        <LanguageSwitcher />
                    </Box>
                    <Box mb={2}>
                        <Typography variant="h5" component="h1" gutterBottom>{record.asset ? record.asset.name : 'Ticket'} n°{record?.id}</Typography>
                    </Box>
                    <Box mb={2}>
                        <Card variant="outlined">
                            <CardContent>
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                                    <Typography variant="h6" component="h5">{t('Détails')}</Typography>
                                    <Box display="flex" alignItems="center">
                                        {['resolved', 'to_control'].includes(record.ticket_state?.code) && (
                                            <Box mr={1} display="flex" justifyContent="center">
                                                <ResolvedIcon />
                                            </Box>
                                        )}
                                        {record?.worker_id && <ChipField label={t('Assigné')} color="primary" />}
                                    </Box>
                                </Box>
                                {record?.room?.number && (
                                    <LabelContainer>
                                        <Label>{t('Chambre')}:</Label>
                                        <Typography>n°{record?.room?.number}</Typography>
                                    </LabelContainer>
                                )}
                                <LabelContainer>
                                    <Label>{t('Catégorie')}:</Label>
                                    <Typography>{record?.maintenance_category_value}</Typography>
                                </LabelContainer>
                                <LabelContainer>
                                    <Label>{t('Priorité')}:</Label>
                                    <PriorityField record={record} />
                                </LabelContainer>
                                <LabelContainer>
                                    <Label>{t('Étage')}:</Label>
                                    <Typography>{record?.floor_value}</Typography>
                                </LabelContainer>
                                <LabelContainer>
                                    <Label>{t('Pièce concernée')}:</Label>
                                    <Typography>{record?.piece_value}</Typography>
                                </LabelContainer>
                                <LabelContainer>
                                    <Label>{t('Code de la boite à clefs')}:</Label>
                                    <Typography>{record?.asset?.code_door_key_box}</Typography>
                                </LabelContainer>
                            </CardContent>
                        </Card>
                    </Box>
                    {(record?.equipments || record?.tools) && (
                        <Box mb={2}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" component="h5" gutterBottom>{t('Infos')}</Typography>
                                    {record.equipments && (
                                        <Box mb={3}>
                                            <Typography><b>{t('Matériel')}</b></Typography>
                                            <Typography>{record?.equipments}</Typography>
                                        </Box>
                                    )}
                                    {record.tools && (
                                        <Box>
                                            <Typography><b>{t('Outils')}</b></Typography>
                                            <Typography>{record?.tools}</Typography>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Box>
                    )}
                    <Box mb={2}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="h5" gutterBottom>{t('Résumé du locataire')}</Typography>
                                <Typography>{record.resume_tenant}</Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    {record.resume_ikoab && (
                        <Box mb={2}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" component="h5" gutterBottom>{t('Commentaires IKOAB')}</Typography>
                                    <Typography>{record.resume_ikoab}</Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    )}
                    <Box mb={2}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" component="h5" gutterBottom>{t('Photos / Vidéos')}</Typography>
                                <Gallery pictures={record.pictures} videos={record.videos} />
                            </CardContent>
                        </Card>
                    </Box>
                    <Box my={5}>
                        <Divider />
                    </Box>
                    <Box mb={1}>
                        <TicketMaterials record={record} />
                    </Box>
                    <Box my={5}>
                        <Divider />
                    </Box>
                    <Box mb={2} ref={ref}>
                        <Typography variant="h5" gutterBottom>{t('Ticket résolu?')}</Typography>
                        <FormControl fullWidth>
                            <StyledRadioGroup value={ticketState} onChange={handleChangeTicketState}>
                                <StyledFormControlLabel
                                    value="to_control"
                                    control={<Radio icon={<CircleIcon color="white" />} checkedIcon={<CheckIcon color="success" />} />}
                                    label={t('Oui')}
                                    sx={{ backgroundColor: '#54D219' }}
                                />
                                <StyledFormControlLabel
                                    value="unresolved"
                                    control={<Radio icon={<CircleIcon color="white" />} checkedIcon={<CloseIcon color="error" />} />}
                                    label={t('Non')}
                                    sx={{ backgroundColor: '#E74C3C' }}
                                />
                            </StyledRadioGroup>
                        </FormControl>
                    </Box>
                    {record.maintenance_type === 'lump_sum' &&
                        <Box mb={2}>
                            <TextField
                                label={t('Prix')}
                                type="number"
                                required
                                fullWidth
                                value={technicianPrice}
                                onChange={handleChangeTechnicianPrice}
                                error={technicianPriceError !== ""}
                                helperText={technicianPriceError}
                            />
                        </Box>
                    }
                    <Box mb={2}>
                        <TextField
                            label={t('Notes')}
                            multiline
                            rows={4}
                            fullWidth
                            value={resumeTechnician}
                            onChange={handleChangeNotes}
                        />
                    </Box>
                    {uploadProgress !== 0 &&
                        <Box mb={1}>
                            <Typography sx={{ padding: '5px', fontWeight: 'bold' }}>
                                {t('Envoie des fichiers...')}{uploadProgress}%
                            </Typography>
                        </Box>
                    }
                    <Box mb={1}>
                        <Dropzone files={documents} setFiles={setDocuments} />
                        {validationError && (
                            <Box mt={1} mb={2}>
                                <Typography color="error">{validationError}</Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ position: 'sticky', bottom: 0, width: '100%', zIndex: 4, padding: '20px 0', backgroundColor: '#FFF' }} mt={5}>
                        <Button color="primary" variant="contained" size="large" onClick={handleSubmit} fullWidth disabled={saveButtonDisabled}>
                            <Typography sx={{ padding: '5px', fontWeight: 'bold' }}>{t('Sauvegarder')}</Typography>
                        </Button>
                    </Box>
                </Box>
            </Container>
            <Modal open={openModal} onClose={handleCloseModal}>
                <h4>{t('Ton ticket doit être validé')}</h4>
                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <Button variant="outlined" onClick={handleBack}>{t('Ne pas valider')}</Button>
                    <Button variant="contained" onClick={handleCloseModal}>{t('Valider')}</Button>
                </Box>
            </Modal>
        </>
    );
};

export default TicketEdit;
